import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { isEmpty } from './index'

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
export const required = value => (value && value.length ? true : 'Campo obbligatorio')
export const requiredIf = (value, othValue) => {
  if (!othValue) {
    return value && value.length ? true : 'Campo obbligatorio'
  }

  return true
}

export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'Email non valida'
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    'Il campo deve contenere un carattere in maiuscolo, uno in minuscolo, un numero, un carattere speciale fra questi !@#$%&*'
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'Le due password non combaciano'

export const maxlenght = (value, max) => () => (max >= value.length) || `Lunghezza massima ${max} caratteri`

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Inserire un numero da ${min} e ${max}`
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'Il campo deve essere un numero'
}

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid'
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'Il campo deve contenere solo caratteri'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'URL is invalid'
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `${length} caratteri richiesti`
}

export const pIvaValidator = (value, length, otherLenght) => {
  if (isEmpty(value)) {
    return true
  }

  return (value.length === length || value.length === otherLenght) || '5 o 11 caratteri richiesti'
}

export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid'
}

export const alphaWithSpacesValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[a-zA-ZÀ-ÿ\s]*$/.test(String(value)) || 'Il campo può contenere solo caratteri alfabetici.'
}

export const alphaSlashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[a-zA-Z0-9_./]*$/i.test(String(value)) || 'Sono ammessi solo numeri, caratteri o /'
}

export const fileRequired = value => (value ? true : 'File obbligatorio')
export const fileMaxSize = (value, size = 8000000) => ((value && value.size < size) || value === undefined ? true : 'Il file è troppo pesante (>8MB)')
export const fileExtensionAccepted = (value, mimes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']) => ((value && mimes.includes(value.type)) || value === undefined ? true : 'Formato file non corretto')

export const serialNumberSyntaxValidator = serialNumber => (!!(serialNumber && serialNumber !== 'Non Leggibile' && serialNumber.length >= 6 && serialNumber.substring(0, 6).match(/^[0-9]+$/) != null && dayjs(`20${serialNumber.substring(0, 6)}`, 'YYYYMMDD', true).isValid()))

export const checkDateIsSameOrBefore42Months = (date, message = 'Data over 42 mesi') => ((date === undefined || dayjs(date).isSameOrAfter(dayjs().subtract(42, 'months'), 'day')) ? true : message)

export const checkIfSerialNumberIsValidAndUnder42Months = serialNumber => {
  let result = true
  if (serialNumber && serialNumber !== 'Non Leggibile' && serialNumber.length >= 6) {
    result = serialNumberSyntaxValidator(serialNumber)

    if (result === true) {
      result = checkDateIsSameOrBefore42Months(dayjs(`20${serialNumber.substring(0, 6)}`), 'Numero seriale non valido con i termini di garanzia')

      return result
    }

    return result
  }

  return true
}

export const checkIfDateOfPurchaseUnder42Months = dateOfPurchase => {
  let result = true
  if (dateOfPurchase) {
    result = checkDateIsSameOrBefore42Months(dateOfPurchase)
  }

  return result
}

// Controllo che la data fornita sia entro la data della scadenza della garanzia, altrimenti da errore
export const checkValidityAssistanceIsBeforeExpMonths = (date, expMonth) => ((date === undefined || expMonth === undefined || dayjs(date).isSameOrAfter(dayjs().subtract(expMonth, 'month'), 'day')) ? true : 'Attenzione: La garanzia per il tuo prodotto è scaduta')

// Controllo che la data fornita sia oltre la data della scadenza della garanzia, altrimenti da errore
export const checkDateIsOverExpMonths = (date, expMonth) => (date !== undefined && expMonth !== undefined) && dayjs(date).isBefore(dayjs().subtract(expMonth, 'month').subtract(10, 'day'), 'day')

// Seriale presente
export const checkIfSerialNumberIsPresent = serialNumber => serialNumber !== undefined

// seriale non leggibile
export const checkIfSerialUnReadable = serialNumber => checkIfSerialNumberIsPresent(serialNumber) && serialNumber === 'Non Leggibile'

// prova di aquisto presente
export const checkIfProofOfPurchaseAreSet = noProofOfPurchase => noProofOfPurchase !== undefined

// Controllo che la data fornita sia dopo i 42 mesi altrimenti da errore
export const checkDateIsOver42Months = date => (!!((date === undefined || dayjs(date).isSameOrBefore(dayjs().subtract(42, 'months'), 'day'))))

// seriale presente e leggibile
export const checkIfSerialNumberIsReadable = serialNumber => checkIfSerialNumberIsPresent(serialNumber) && serialNumber !== 'Non Leggibile'

// controllo se ha cliccato "Non ho prova di acquisto"
export const checkIfNoProofOPurchaseAreSet = noProofOfPurchase => noProofOfPurchase !== undefined

// controllo se il seriale inserito è oltre i 42 mesi
export const checkIfSerialNumberIsOver42Month = serialNumber => checkIfSerialNumberIsReadable(serialNumber) && serialNumberSyntaxValidator(serialNumber) && checkDateIsOver42Months(dayjs(`20${serialNumber.substring(0, 6)}`))

export const rulesSyntaxSerialNumberValidator = serialNumber => {
  if (checkIfSerialUnReadable(serialNumber)) {
    return true
  }

  return serialNumberSyntaxValidator(serialNumber) ? true : 'Numero seriale (Date Code) non valido'
}

// controllo che il datacode inserito non sia falso
export const checkIfDataCodeIsFake = serialNumber => (
  serialNumber === undefined || serialNumber === 'Non Leggibile'
  || !['10100202241', '18072802112C886', '19021700664B455', '18072802072C886', '18072400654C891', '20120200007AC13', '20120200007AC13'].includes(serialNumber.toUpperCase())
    ? true : 'Numero seriale non valido')

export const checkIfDateOfPurchaseIsInWarranty = (date, expMonth) => ((date === undefined || expMonth === undefined || dayjs(date).isSameOrAfter(dayjs().subtract(expMonth, 'month'), 'day')))

export const checkIfDateOfPurchaseIsBeetween42MonthAndExpMonths = (serialNumber, expMonths) => (serialNumber !== undefined || expMonths !== undefined) && (!checkIfSerialNumberIsOver42Month(serialNumber) && checkDateIsOverExpMonths(`20${serialNumber.substring(0, 6)}`, expMonths))

export const checkIfDateCodeIsInside42Month = serialNumber => !checkIfSerialNumberIsOver42Month(serialNumber)

export const dateOfPurchaseWarrantyValidity = (serialNumber, dateOfPurchase) => {
  let result = true
  if (dateOfPurchase || serialNumber) {
    if (serialNumber && checkIfSerialNumberIsReadable(serialNumber) && serialNumberSyntaxValidator(serialNumber) === true) {
      let serialNumberDate = serialNumber.substring(0, 6)

      serialNumberDate = dayjs(`20${serialNumberDate}`)
      if (dateOfPurchase) {
        // controllo che la data di acquisto sia posteriore o uguale alla data del seriale
        if (dayjs(serialNumberDate).isAfter(dateOfPurchase, 'day')) {
          result = 'Per continuare è necessario inserire un seriale corretto o selezionare "Non leggibile"'
        }
      }
    }
  }

  return result
}

export const checkIfSelectedFallDamage = damage => damage === 'D008'

export const checkGeneralValidity = (product, expMonths) => {
  if (product.autocompiled && product.isOwnCodDocument) {
    return product.gar
  }
  const isPurchaseDateValid = product.purchaseDate
    && !checkDateIsOverExpMonths(product.purchaseDate, expMonths)
  const isSerialNumberValid = checkIfSerialNumberIsReadable(product.serialNumber)
  const isDamageValid = !checkIfSelectedFallDamage(product.damage)

  return isPurchaseDateValid && isSerialNumberValid && isDamageValid ? 1 : 0
}

// REGOLE NEL CASO IN CUI NON POSSA INVIARE IL PRODOTTO
export const checkIfSerialNumberOrProofOfPurchaseAreSet = (serialNumber, noProofOfPurchase) => checkIfSerialNumberIsReadable(serialNumber) || noProofOfPurchase !== true
